import { isBefore } from 'date-fns'

const oldFruitMousseCupSalePriceMap: Record<string, number> = {
  // mousse hoa qua
  V8321: 190000,
  V8323: 220000,
  V8327: 450000,
  // mousse moi
  V9227: 190000,
  V9229: 220000,
  V9231: 450000,
  // mix 12
  V8799: 190000
}
const fruitMousseCupSalePriceMap: Record<string, number> = {
  // mousse hoa qua
  V8321: 150000,
  V8323: 190000,
  V8327: 350000,
  // mousse moi
  V9227: 150000,
  V9229: 190000,
  V9231: 350000,
  // mix 12
  V8799: 150000
}
export function getFruitMousseCupSalePriceMap(deadline: Date) {
  if (isBefore(deadline, new Date('2024-10-25T00:00:00+07:00'))) {
    return oldFruitMousseCupSalePriceMap
  }
  return fruitMousseCupSalePriceMap
}

export function getFruitMousseCupSkus() {
  return [...Object.keys(fruitMousseCupSalePriceMap)]
}

export const teacherDaySkus = [
  'V9267',
  // bánh cốc
  'V8791',
  'V8793',
  'V8795',
  'V9041',
  'V9043',
  'V9045',
  'V9065',
  'V9067',
  'V9069',
  'V8799',
  'V9227',
  'V9229',
  'V9231',
  'V8321',
  'V8323',
  'V8327',
  'V8329',
  'V8331',
  'V8335',
  'V9071',
  'V9073',
  'V9075'
]
export function getTeacherDayGiftSkusMap() {
  const auto = ['V9262'] // que mica
  const picky = ['V2863', 'V2865'] // que giấy tùy chọn

  return {
    auto,
    picky,
    all: [...auto, ...picky]
  }
}

export const tetCookieSkus = ['V9276', 'V9277', 'V9278']
export const xmasCakeSkus = [
  'V8294',
  'V8296',
  'V8298',
  'V9317',
  'V9318',
  'V9319',
  'V1961',
  'V1968',
  'V1975',
  'V9306',
  'V9309',
  'V9310',
  'V9311',
  'V9312',
  'V9296',
  'V9297',
  'V9298',
  'V9299',
  'V8306'
]
export function getXmasGiftSkusMap() {
  return {
    quantity: ['V9308'], // que cắm
    value: ['V2005'] // thiệp
  }
}

export const valentineSkusMap = {
  box: ['V9401', 'V9403', 'V9163']
}
export function getValentineGiftSkusMap() {
  return {
    quantity: ['V9400'] // que cắm
  }
}
