import { decorate, observable, computed, action, runInAction } from 'mobx'
import { IOutlet } from './outlet-list.store'
import store from 'store'
import { RootStore } from './root-store'
import { getObjectId } from '../libs/object-id'
import { get } from 'lodash'

export interface IShiftRecord {
  _id: string
  shiftRecordId: string
  outlet: IOutlet | string
  shift: TShift
  date: Date | string
}

export interface IShiftEntry {
  _id: string
  outlet: IOutlet
  shift: TShift
  date: Date | string
  staffs: { _id: string; code?: string; displayName: string }[]
}

export type TShift = 'S' | 'S_12' | 'C' | 'T' | 'T_1'

export class CurrentShiftStore {
  rootStore!: RootStore
  loadRootStore(rootStore: RootStore) {
    runInAction(() => {
      this.rootStore = rootStore
    })
  }

  currentShift: IShiftRecord | null = null
  currentOutlet: string = ''

  get loggedInShift() {
    return this.currentShift && this.currentShift.shiftRecordId.length > 0
  }
  get outlet() {
    return this.currentShift ? this.currentShift.outlet : null
  }
  setCurrentShift(shiftRecord: IShiftRecord | null) {
    this.currentShift = shiftRecord
    store.set('currentShift', shiftRecord)
  }
  logOutShift() {
    if (this.currentShift) {
      this.setCurrentShift(null)
      this.clearData()
    }
  }

  loadLocalData() {
    this.currentShift = store.get('currentShift', null)
  }
  clearData() {
    store.remove('currentShift')
  }
  async checkCurrentShift(currentShift: {
    outlet: IOutlet | string
    date: Date | string
    shift: TShift
    currentLocation: any
  }) {
    const data = await this.rootStore.agent.get<{
      exists: boolean
      shiftRecord: IShiftRecord
      shiftEntry: IShiftEntry
    }>('/shift-records/check', {
      params: {
        outlet: getObjectId(currentShift.outlet),
        date: currentShift.date,
        shift: currentShift.shift,
        lat: get(currentShift, 'currentLocation.lat'),
        lng: get(currentShift, 'currentLocation.lng')
      }
    })
    if (data.exists && data.shiftRecord) {
      this.setCurrentShift(data.shiftRecord)
    }
    return data
  }
  async createShiftRecord(currentShift: {
    outlet: IOutlet | string
    date: Date | string
    shift: TShift
    initialPettyCash: number
    staffs: any[]
    employees: any[]
  }) {
    const shiftRecord = await this.rootStore.agent.post<IShiftRecord>(
      '/shift-records',
      currentShift
    )
    this.setCurrentShift(shiftRecord)
    return shiftRecord
  }

  setCurrentOutlet(value: string) {
    this.currentOutlet = value
  }
}

decorate(CurrentShiftStore, {
  currentOutlet: observable,
  currentShift: observable,
  loggedInShift: computed,
  outlet: computed,
  setCurrentShift: action,
  setCurrentOutlet: action
})

export function getCodeFromShiftRecordId(shiftRecordId: string | null) {
  if (!shiftRecordId) return ''
  const matches = shiftRecordId.match(/(.*)_\d{4}-\d+-\d+_[SCT]/)

  if (!matches) return ''
  return matches[1]
}
