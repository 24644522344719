import React, { Fragment, ReactNode, useContext } from 'react'

import { CreateOrderView } from '../views/CreateOrderView/CreateOrderView'
import { NotFoundView } from '../views/NotFoundView'
import { Helmet } from 'react-helmet'
import { ListOrdersView } from '../views/ListOrdersView/ListOrdersView'
import { Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { ListSaleEntriesView } from '../views/ListSaleEntriesView/ListSaleEntriesView'
import { UpdateProductStatuses } from '../views/UpdateProductStatuses/UpdateProductStatuses'
import { FoodPrep } from '../views/FoodPrep/FoodPrep'
import { LoginView } from '../views/LoginView/LoginView'
import { PrintSaleEntry } from '../views/PrintSaleEntry/PrintSaleEntry'
import { RootStoreContext } from '../model/root-store'

interface IRoute {
  path: string | undefined
  exact: boolean
  title: () => any
  main: any
  visibleOnMenu: boolean
  header: () => any
  needAuthentication?: boolean
}

export const routes: IRoute[] = [
  {
    path: '/orders/create',
    exact: true,
    title: () => {
      const rootStore = useContext(RootStoreContext)
      const orderStore = rootStore.orderStore
      const outletCode = orderStore.getOutletCode()
      return (
        <Fragment>
          {outletCode === 'HOICHO' ? (
            <Menu.Item
              header
              as={Link}
              to="/orders/create?collection=hoi-cho"
              active={location.search.includes('hoi-cho')}
            >
              Hội chợ
            </Menu.Item>
          ) : null}
          {!['S17', 'S7'].includes(outletCode) ? (
            <Menu.Item
              header
              as={Link}
              to="/orders/create?category=birthday-cake"
              active={location.search.includes('birthday-cake')}
            >
              Bánh Sinh nhật
            </Menu.Item>
          ) : null}
          <Menu.Item
            header
            as={Link}
            to="/orders/create?category=banh-keo-tet"
            active={location.search.includes('banh-keo-tet')}
          >
            Bánh kẹo Tết
          </Menu.Item>
          {/* <Menu.Item
            header
            as={Link}
            to="/orders/create?category=mooncake"
            active={location.search.includes('mooncake')}
          >
            Bánh Trung Thu
          </Menu.Item> */}
          <Menu.Item
            header
            as={Link}
            to="/orders/create?category=combo-savor"
            active={location.search.includes('combo-savor')}
          >
            Combo Savor
          </Menu.Item>
          <Menu.Item
            header
            as={Link}
            to="/orders/create?category=combo"
            active={location.search.includes('combo')}
          >
            Combo Đối tác
          </Menu.Item>
          <Menu.Item
            header
            as={Link}
            to="/orders/create?category=milk-tea"
            active={location.search.includes('milk-tea')}
          >
            Trà & Nước
          </Menu.Item>
          <Menu.Item
            header
            as={Link}
            to="/orders/create?category=bread"
            active={location.search.includes('bread')}
          >
            Bánh mì
          </Menu.Item>
          <Menu.Item
            header
            as={Link}
            to="/orders/create?category=sticky-rice"
            active={location.search.includes('sticky-rice')}
          >
            Xôi
          </Menu.Item>
          <Menu.Item
            header
            as={Link}
            to="/orders/create?category=sandwich"
            active={location.search.includes('sandwich')}
          >
            Sandwich
          </Menu.Item>
          <Menu.Item
            header
            as={Link}
            to="/orders/create?category=gift"
            active={location.search.includes('gift')}
          >
            Đồ tặng
          </Menu.Item>
          {/* <Menu.Item
          header
          as={Link}
          to="/orders/create?category=valentine"
          active={location.search.includes('valentine')}
        >
          Valentine
        </Menu.Item> */}

          <Menu.Item
            header
            as={Link}
            to="/orders/create?category=other"
            active={location.search.includes('other')}
          >
            Khác
          </Menu.Item>
        </Fragment>
      )
    },
    needAuthentication: true,
    main: CreateOrderView,
    visibleOnMenu: true,
    header: () => (
      <Helmet>
        <title>Create Order</title>
      </Helmet>
    )
  },
  {
    path: '/orders',
    exact: true,
    title: () => (
      <Menu.Item header>
        <h3>List Orders</h3>
      </Menu.Item>
    ),
    main: ListOrdersView,
    visibleOnMenu: true,
    header: () => (
      <Helmet>
        <title>List Orders</title>
      </Helmet>
    ),
    needAuthentication: true
  },
  {
    path: '/sale-entries',
    exact: true,
    title: () => (
      <Menu.Item header>
        <h3>List Sale Entries</h3>
      </Menu.Item>
    ),
    main: ListSaleEntriesView,
    visibleOnMenu: true,
    header: () => (
      <Helmet>
        <title>List Sale Entries</title>
      </Helmet>
    ),
    needAuthentication: true
  },
  {
    path: '/print-sale-entries',
    exact: true,
    title: () => (
      <Menu.Item header>
        <h3>Print Sale Entries</h3>
      </Menu.Item>
    ),
    main: PrintSaleEntry,
    visibleOnMenu: true,
    header: () => (
      <Helmet>
        <title>Print Sale Entries</title>
      </Helmet>
    ),
    needAuthentication: true
  },
  {
    path: '/update-stock',
    exact: true,
    title: () => (
      <Menu.Item header>
        <h3>Update Stock</h3>
      </Menu.Item>
    ),
    main: UpdateProductStatuses,
    visibleOnMenu: true,
    header: () => (
      <Helmet>
        <title>Update Stock</title>
      </Helmet>
    ),
    needAuthentication: true
  },
  {
    path: '/food-prep',
    exact: true,
    title: () => (
      <Menu.Item header>
        <h3>Food Prep</h3>
      </Menu.Item>
    ),
    main: FoodPrep,
    visibleOnMenu: true,
    header: () => (
      <Helmet>
        <title>Quy đổi</title>
      </Helmet>
    ),
    needAuthentication: true
  },
  {
    path: '/login',
    needAuthentication: false,
    exact: true,
    title: () => (
      <Menu.Item header>
        <h3>Login</h3>
      </Menu.Item>
    ),
    main: LoginView,
    visibleOnMenu: true,
    header: () => (
      <Helmet>
        <title>Login</title>
      </Helmet>
    )
  },
  {
    path: undefined,
    exact: false,
    title: () => (
      <Menu.Item header>
        <h3>Not found!</h3>
      </Menu.Item>
    ),
    main: NotFoundView,
    visibleOnMenu: true,
    header: () => (
      <Helmet>
        <title>Not Found!</title>
      </Helmet>
    )
  }
]
