import React, { useContext, useEffect } from 'react'

import { Grid, Container, Segment } from 'semantic-ui-react'
import { CurrentOrder } from './CurrentOrder/CurrentOrder'
import { ItemGrid } from './ItemGrid'

import { RootStoreContext } from '../../model/root-store'
import { withRouter, RouteComponentProps } from 'react-router'
import { observer } from 'mobx-react-lite'

import { PrintSaleEntry } from '../PrintSaleEntry/PrintSaleEntry'

const ItemGridView = withRouter(
  observer(function ItemGridView({ location }: RouteComponentProps) {
    const rootStore = useContext(RootStoreContext)
    const uiStore = rootStore.uiStore
    const params = new URLSearchParams(location.search)
    return (
      <Grid.Column
        width={uiStore.createOrderViewLayout === 'split' ? 10 : 16}
        style={{
          padding: '5px 10px 5px 20px',
          height: '100%',
          overflowY: 'auto',
          WebkitOverflowScrolling: 'touch',
          overflowX: 'hidden'
        }}
      >
        <ItemGrid
          showItems={!!location.search}
          category={params.get('category')}
          collection={params.get('collection')}
        />
      </Grid.Column>
    )
  })
)

export const CreateOrderView = observer(function CreateOrderView() {
  const { uiStore, orderStore } = useContext(RootStoreContext)

  return (
    <>
      <PrintSaleEntry successText="Tạo Sale Entry thành công!" />
      <Grid
        padded={false}
        style={{
          height: '100%',
          marginBottom: 0,
          marginTop: 0,
          opacity: orderStore.orderPrinting && orderStore.saleEntryPrinting ? 0 : 1
        }}
      >
        {(uiStore.createOrderViewLayout === 'split' ||
          uiStore.createOrderViewLayout === 'items') && <ItemGridView />}

        <Grid.Column
          width={uiStore.createOrderViewLayout === 'split' ? 6 : 16}
          stretched
          style={{
            padding: '5px 20px 5px 0px',
            height: '100%',
            marginLeft: uiStore.createOrderViewLayout === 'order' ? 20 : undefined
          }}
        >
          <CurrentOrder
            onExpand={() => {
              if (uiStore.createOrderViewLayout === 'split') {
                uiStore.changeCreateOrderViewLayout('order')
              } else {
                uiStore.changeCreateOrderViewLayout('split')
              }
            }}
          />
        </Grid.Column>
      </Grid>
    </>
  )
})
