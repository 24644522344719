import { observable, decorate, action, computed } from 'mobx'
import { clone } from 'lodash'
import { IComputedItem, TOrderPlatform } from './order.store'

export interface IModifier {
  sku: string
  description: string
  appliedProductSkus?: string[]
  appliedCategoryCodes?: string[]
  excludedProductSkus?: string[]
  price: number | null
  modifierSetCode: string
  maxQuantity?: number
  orderPlatforms?: {
    code: TOrderPlatform
    normalPrice?: number
  }[]
}

export interface IModifierSet {
  name: string
  code: string
  maxTotalQuantity?: number
  minTotalQuantity?: number
}

export interface IComputedModifierSet extends IModifierSet {
  modifiers: IModifier[]
}

export class ModifierStore {
  modifiers: IModifier[] = []
  modifierSets: IModifierSet[] = []
  loadModifiers(modifiers: IModifier[]) {
    this.modifiers = modifiers.map(modifier => {
      return modifier
    })
  }
  loadModiferSets(modifierSets: IModifierSet[]) {
    this.modifierSets = modifierSets
  }
  get computedModifierSets() {
    return this.modifierSets.map(modifierSet => {
      const modifiers = this.modifiers.filter(
        modifier => modifier.modifierSetCode === modifierSet.code
      )
      return { ...modifierSet, modifiers }
    })
  }
  isValidModifier(modifier: IModifier, item: IComputedItem) {
    const isExcluded =
      modifier.excludedProductSkus && modifier.excludedProductSkus.includes(item.sku)
    return (
      !isExcluded &&
      ((modifier.appliedProductSkus && modifier.appliedProductSkus.includes(item.sku)) ||
        (modifier.appliedCategoryCodes && modifier.appliedCategoryCodes.includes(item!.category)))
    )
  }
  getComputedModifierSets(item: IComputedItem) {
    const computedModifierSets = clone(this.computedModifierSets).map(computedModifierSet => {
      return {
        ...computedModifierSet,
        modifiers: computedModifierSet.modifiers.filter(modifier => {
          return this.isValidModifier(modifier, item)
        })
      }
    })

    return computedModifierSets.filter(computedModifierSet => computedModifierSet.modifiers.length)
  }
}

decorate(ModifierStore, {
  modifiers: observable,
  modifierSets: observable,
  loadModifiers: action,
  loadModiferSets: action,
  computedModifierSets: computed
})

export const modifierStore = new ModifierStore()

const SUGAR_MODIFY_SKUS = [
  'V9081',
  'V9082',
  'V9083',
  'V9084',
  'V9085',
  'V9086',
  'V9087',
  'V9088',
  'V9089',
  'V9090',
  'V9091',
  'V0695',
  'V0696',
  'V0608',
  'V0613',
  'V0699',
  'V0709',
  'V0708',
  'V0710',
  'V0694',
  'V0713',
  'V0714',
  'V0944',
  'V2327',
  'V2328',
  'V2329',
  'V2330',
  'V2331',
  'V2332',
  'V2333',
  'V2334'
]

modifierStore.loadModifiers([
  {
    sku: 'V0090',
    description: 'Sốt thịt',
    price: null,
    appliedCategoryCodes: ['bread'],
    modifierSetCode: 'bread-addon',
    maxQuantity: 1
  },
  {
    sku: 'V0091',
    description: 'Sốt dầu trứng',
    price: null,
    appliedCategoryCodes: ['bread'],
    modifierSetCode: 'bread-addon',
    maxQuantity: 1
  },
  {
    sku: 'V0283',
    description: 'Tương cà chua',
    price: null,
    appliedCategoryCodes: ['bread'],
    modifierSetCode: 'bread-addon',
    maxQuantity: 1
  },
  {
    sku: 'V0198',
    description: 'Ớt ngọt',
    price: null,
    appliedCategoryCodes: ['bread'],
    modifierSetCode: 'bread-addon',
    maxQuantity: 2
  },
  {
    sku: 'V0198',
    description: 'Ớt ngọt',
    price: null,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'addon',
    maxQuantity: 2
  },
  {
    description: 'Dưa chuột',
    sku: 'V0081',
    price: null,
    appliedCategoryCodes: ['bread'],
    modifierSetCode: 'veggie',
    maxQuantity: 1
  },
  {
    sku: 'V8517',
    description: 'Dưa chuột (BMGX mới)',
    price: null,
    appliedCategoryCodes: ['bread'],
    modifierSetCode: 'veggie',
    maxQuantity: 1
  },
  {
    sku: 'V0085',
    description: 'Rau mùi',
    price: null,
    appliedCategoryCodes: ['bread'],
    modifierSetCode: 'veggie',
    maxQuantity: 1
  },
  {
    sku: 'V8516',
    description: 'Rau mùi (BMGX mới)',
    price: null,
    appliedCategoryCodes: ['bread'],
    modifierSetCode: 'veggie',
    maxQuantity: 1
  },
  {
    sku: 'V0087',
    description: 'Nộm chua ngọt',
    price: null,
    appliedCategoryCodes: ['bread'],
    modifierSetCode: 'veggie',
    maxQuantity: 1
  },
  {
    sku: 'V8515',
    description: 'Nộm su hào cà rốt (BMGX mới)',
    price: null,
    appliedCategoryCodes: ['bread'],
    modifierSetCode: 'veggie',
    maxQuantity: 1
  },
  {
    sku: 'V0396',
    description: 'Ruốc (5g)',
    price: 3000,
    appliedCategoryCodes: ['bread'],
    excludedProductSkus: ['V8337', 'V8338'],
    modifierSetCode: 'extra',
    maxQuantity: 1
  },
  {
    sku: 'V0360',
    description: 'Xúc xích',
    price: 5000,
    appliedCategoryCodes: ['bread'],
    excludedProductSkus: ['V8338'],
    modifierSetCode: 'extra',
    maxQuantity: 6
  },
  {
    sku: 'V0360',
    description: 'Xúc xích',
    price: 5000,
    appliedProductSkus: ['V8337'],
    modifierSetCode: 'extra',
    maxQuantity: 1
  },
  {
    sku: 'V0089',
    description: 'Patê',
    price: 2000,
    appliedCategoryCodes: ['bread'],
    excludedProductSkus: ['V8337', 'V8338'],
    modifierSetCode: 'extra',
    maxQuantity: 1
  },
  {
    sku: 'V0353',
    description: 'Xôi',
    price: 18000,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'xoi',
    maxQuantity: 1
  },
  {
    sku: 'V1561',
    description: 'Xôi thêm',
    price: 6000,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'extra',
    maxQuantity: 5
  },
  {
    sku: 'V0364',
    description: 'Nộm dưa chuột',
    price: 1000,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'addon',
    maxQuantity: 5
  },
  {
    sku: 'V0384',
    description: 'Hành khô',
    price: 2000,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'addon',
    maxQuantity: 5
  },
  {
    sku: 'V0362',
    description: 'Gà sốt nấm',
    price: 20000,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'extra',
    maxQuantity: 5,
    orderPlatforms: [{ code: 'GRAB_FOOD', normalPrice: 17000 }]
  },
  {
    sku: 'V8506',
    description: 'Gà xé',
    price: 15000,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'extra',
    maxQuantity: 5
  },
  {
    sku: 'V0355',
    description: 'Thịt kho',
    price: 17000,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'extra',
    maxQuantity: 5
  },
  {
    sku: 'V0360',
    description: 'Xúc xích',
    price: 5000,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'extra',
    maxQuantity: 5
  },
  {
    sku: 'V0359',
    description: 'Lạp xưởng',
    price: 5000,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'extra',
    maxQuantity: 5
  },
  {
    sku: 'V9413',
    description: 'Free topping lạp xưởng',
    price: 0,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'extra',
    maxQuantity: 1
  },
  {
    sku: 'V1158',
    description: 'Sườn cay',
    price: 9000,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'extra',
    maxQuantity: 10
  },
  {
    sku: 'V0356',
    description: 'Trứng kho',
    price: 6000,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'extra',
    maxQuantity: 5
  },
  {
    sku: 'V0543',
    description: 'Trứng ốp',
    price: 6000,
    appliedCategoryCodes: ['sticky-rice', 'bread'],
    excludedProductSkus: ['V8337', 'V8338'],
    modifierSetCode: 'extra',
    maxQuantity: 5
  },
  {
    sku: 'V0601',
    description: 'Ruốc kèm combo',
    price: null,
    appliedProductSkus: ['V0378', 'V0377'],
    modifierSetCode: 'extra',
    maxQuantity: 1
  },
  {
    sku: 'V0350',
    description: 'Ruốc',
    price: 6000,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'extra',
    maxQuantity: 5
  },
  {
    sku: 'V0383',
    description: 'Pate',
    price: 6000,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'extra',
    maxQuantity: 5
  },
  {
    sku: 'V0358',
    description: 'Chả',
    price: 6000,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'extra',
    maxQuantity: 5
  },
  {
    sku: 'V1046',
    description: 'Hộp giấy giấy bạc',
    price: 3000,
    appliedCategoryCodes: ['sticky-rice'],
    modifierSetCode: 'addon',
    maxQuantity: 1
  },
  {
    sku: 'V0621',
    description: '100% đá',
    price: null,
    appliedProductSkus: SUGAR_MODIFY_SKUS,
    modifierSetCode: 'ice',
    maxQuantity: 1
  },
  {
    sku: 'V0622',
    description: '70% đá',
    price: null,
    appliedProductSkus: SUGAR_MODIFY_SKUS,
    modifierSetCode: 'ice',
    maxQuantity: 1
  },
  {
    sku: 'V0623',
    description: '0% đá',
    price: null,
    appliedProductSkus: SUGAR_MODIFY_SKUS,
    modifierSetCode: 'ice',
    maxQuantity: 1
  },
  {
    sku: 'V0720',
    description: '120% đường',
    price: null,
    appliedProductSkus: SUGAR_MODIFY_SKUS,
    modifierSetCode: 'sugar',
    maxQuantity: 1
  },
  {
    sku: 'V0618',
    description: '100% đường',
    price: null,
    appliedProductSkus: SUGAR_MODIFY_SKUS,
    modifierSetCode: 'sugar',
    maxQuantity: 1
  },
  {
    sku: 'V0619',
    description: '70% đường',
    price: null,
    appliedProductSkus: SUGAR_MODIFY_SKUS,
    modifierSetCode: 'sugar',
    maxQuantity: 1
  },
  {
    sku: 'V0620',
    description: '0% đường',
    price: null,
    appliedProductSkus: ['V0694'],
    modifierSetCode: 'sugar',
    maxQuantity: 1
  },
  {
    sku: 'V9091',
    description: 'Topping thạch đào',
    price: 5000,
    appliedCategoryCodes: ['milk-tea'],
    modifierSetCode: 'addon-milk-tea',
    maxQuantity: 5
  },
  {
    sku: 'V0658',
    description: 'Kem cheese',
    price: 10000,
    appliedCategoryCodes: ['milk-tea'],
    modifierSetCode: 'addon-milk-tea',
    maxQuantity: 5
  },
  {
    sku: 'V0657',
    description: 'Trân châu trắng',
    price: 5000,
    appliedCategoryCodes: ['milk-tea', 'juice'],
    modifierSetCode: 'addon-milk-tea',
    maxQuantity: 5
  },
  {
    sku: 'V0759',
    description: 'Nha đam',
    price: 5000,
    appliedCategoryCodes: ['milk-tea', 'juice'],
    modifierSetCode: 'addon-milk-tea',
    maxQuantity: 5
  },
  {
    sku: 'V0656',
    description: 'Trân châu đường đen',
    price: 5000,
    appliedCategoryCodes: ['milk-tea'],
    modifierSetCode: 'addon-milk-tea',
    maxQuantity: 5
  },
  {
    sku: 'V1273',
    description: 'Chà bông gà cay',
    price: 9000,
    appliedCategoryCodes: ['cake'],
    modifierSetCode: 'addon-cake',
    maxQuantity: 5
  },
  {
    sku: 'V1274',
    description: 'Chà bông heo',
    price: 9000,
    appliedCategoryCodes: ['cake'],
    modifierSetCode: 'addon-cake',
    maxQuantity: 5
  },
  {
    sku: 'V1275',
    description: 'Sốt bơ trứng',
    price: 5000,
    appliedCategoryCodes: ['cake'],
    modifierSetCode: 'addon-cake',
    maxQuantity: 5
  },
  {
    sku: 'V1276',
    description: 'Sốt phô mai',
    price: 15000,
    appliedCategoryCodes: ['cake'],
    modifierSetCode: 'addon-cake',
    maxQuantity: 5
  },
  {
    sku: 'V1277',
    description: 'Trứng muối nghiền',
    price: 10000,
    appliedCategoryCodes: ['cake'],
    modifierSetCode: 'addon-cake',
    maxQuantity: 5
  },
  {
    sku: 'V1269',
    description: 'Bánh mì sốt trứng muối ruốc',
    appliedCategoryCodes: ['V1000'],
    modifierSetCode: 'cake',
    maxQuantity: 1,
    price: 49000
  },
  {
    sku: 'V0297',
    description: 'Thêm thịt nướng mật ong (30g)',
    modifierSetCode: 'extra',
    appliedProductSkus: ['V0287'],
    price: 5000,
    maxQuantity: 5
  },
  {
    sku: 'V0222',
    description: 'Thêm gà sốt teriyaki (30g)',
    modifierSetCode: 'extra',
    appliedProductSkus: ['V0096'],
    price: 5000,
    maxQuantity: 1
  },
  {
    sku: 'V8340',
    description: 'Thêm trứng (Bánh mì trứng tự chọn)',
    modifierSetCode: 'extra',
    appliedProductSkus: ['V8337'],
    price: 6000,
    maxQuantity: 1
  },
  {
    sku: 'V8344',
    description: 'Thêm thịt nướng (Bánh mì tự chọn)',
    modifierSetCode: 'extra',
    appliedProductSkus: ['V8337', 'V8504'],
    price: 7000,
    maxQuantity: 1
  },
  {
    sku: 'V8341',
    description: 'Thêm chả (Bánh mì tự chọn)',
    modifierSetCode: 'extra',
    appliedProductSkus: ['V8337', 'V8504'],
    price: 5000,
    maxQuantity: 1
  },
  {
    sku: 'V8342',
    description: 'Thêm pate (Bánh mì tự chọn)',
    modifierSetCode: 'extra',
    appliedProductSkus: ['V8337', 'V8504'],
    price: 3000,
    maxQuantity: 1
  },
  {
    sku: 'V8343',
    description: 'Thêm ruốc (Bánh mì tự chọn)',
    modifierSetCode: 'extra',
    appliedProductSkus: ['V8337', 'V8504'],
    price: 3000,
    maxQuantity: 1
  },
  {
    sku: 'V9117',
    description: 'Trà đá (M)',
    modifierSetCode: 'drink-addon',
    appliedCategoryCodes: ['bread', 'sticky-rice', 'sandwich'],
    price: 5000,
    maxQuantity: 1
  }
])

modifierStore.loadModiferSets([
  { name: 'Xôi', code: 'xoi' },
  { name: 'Rau', code: 'veggie' },
  { name: 'Sốt', code: 'bread-addon' },
  { name: 'Addon', code: 'addon' },
  { name: 'Addon Milk Tea', code: 'addon-milk-tea' },
  { name: 'Addon Bánh Ngọt', code: 'addon-cake' },
  { name: 'Addon Tính phí', code: 'extra' },
  { name: 'Addon Nước', code: 'drink-addon', maxTotalQuantity: 1 },
  { name: 'Nước Combo', code: 'combo-drink', maxTotalQuantity: 1 },
  { name: 'Mức đá', code: 'ice', maxTotalQuantity: 1, minTotalQuantity: 1 },
  { name: 'Mức đường', code: 'sugar', maxTotalQuantity: 1, minTotalQuantity: 1 }
])
