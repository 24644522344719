import { IProduct } from './product.store'
import { combos } from './combo-products.data'
import { concat } from 'lodash'

export const products: IProduct[] = concat(combos, [
  {
    sku: 'V0287',
    description: 'Bánh mì thịt nướng mật ong',
    normalPrice: 35000,
    category: 'bread',
    imageLink: require('../img/thit-nuong-mat-ong.jpg'),
    defaultModifierSkus: ['V0081', 'V0087', 'V0091'],
    variants: [
      {
        group: { size: 'big' },
        sku: 'V0287',
        description: 'Cỡ to',
        normalPrice: 35000
      }
    ]
  },
  {
    sku: 'V0098',
    description: 'Bánh mì bò sốt tiêu',
    normalPrice: 35000,
    category: 'bread',
    imageLink: require('../img/bo-sot-tieu.jpg'),
    defaultModifierSkus: ['V0081', 'V0084', 'V0090'],
    variants: [
      {
        group: { size: 'big' },
        sku: 'V0098',
        description: 'Cỡ to',
        normalPrice: 35000
      }
    ]
  },
  {
    sku: 'V2861',
    description: 'Bánh mì trứng thịt',
    normalPrice: 30000,
    category: 'bread',
    defaultModifierSkus: ['V0081', 'V0085', 'V0087']
  },
  {
    sku: 'V2862',
    description: 'Bánh mì trứng thịt pate',
    normalPrice: 35000,
    category: 'bread',
    defaultModifierSkus: ['V0081', 'V0085', 'V0087']
  },
  {
    sku: 'V1036',
    description: 'Bánh mì bơ tỏi',
    normalPrice: 15000,
    category: 'bread'
  },
  {
    sku: 'V8504',
    description: 'Bánh mì pate tự chọn',
    normalPrice: 18000,
    category: 'bread',
    defaultModifierSkus: ['V8517', 'V0085', 'V0091']
  },
  {
    sku: 'V8505',
    description: 'Bánh mì pate thập cẩm',
    normalPrice: 35000,
    category: 'bread',
    defaultModifierSkus: ['V8517', 'V0085', 'V0091']
  },
  {
    sku: 'V8337',
    description: 'Bánh mì trứng tự chọn',
    normalPrice: 18000,
    category: 'bread',
    defaultModifierSkus: ['V0085', 'V0081']
  },
  {
    sku: 'V8338',
    description: 'Bánh mì trứng full topping',
    normalPrice: 39000,
    category: 'bread',
    defaultModifierSkus: ['V0085', 'V0081']
  },
  {
    sku: 'V8502',
    description: 'Bánh mì gà xé sốt cay',
    normalPrice: 30000,
    category: 'bread',
    defaultModifierSkus: ['V8517', 'V8515', 'V0085']
  },
  {
    sku: 'V8503',
    description: 'Bánh mì gà xé',
    normalPrice: 30000,
    category: 'bread',
    defaultModifierSkus: ['V8517', 'V8515', 'V0085']
  },
  {
    sku: 'V8500',
    description: 'Xôi gà xé',
    normalPrice: 35000,
    category: 'sticky-rice',
    defaultModifierSkus: ['V0384', 'V0364', 'V0353']
  },
  {
    sku: 'V8501',
    description: 'Xôi gà xé sốt nấm',
    normalPrice: 40000,
    category: 'sticky-rice',
    defaultModifierSkus: ['V0384', 'V0364', 'V0353']
  },
  {
    sku: 'V0379',
    description: 'Xôi ruốc lạp xưởng',
    normalPrice: 30000,
    category: 'sticky-rice',
    imageLink: require('../img/combo-ruoc-lap-xuong.jpg'),
    defaultModifierSkus: ['V0353', 'V0359', 'V0350', 'V0384', 'V0364', 'V1046']
  },
  {
    sku: 'V0381',
    description: 'Xôi thịt trứng chả',
    normalPrice: 45000,
    category: 'sticky-rice',
    imageLink: require('../img/combo-thit-trung-cha.jpg'),
    defaultModifierSkus: ['V0384']
  },

  {
    sku: 'V1023',
    description: 'Xôi thịt, trứng ốp la',
    normalPrice: 40000,
    category: 'sticky-rice',
    imageLink: require('../img/combo-pate-xuc-xich.jpg'),
    defaultModifierSkus: ['V0353', 'V0355', 'V0364', 'V0384', 'V1046', 'V0543']
  },

  {
    sku: 'V1029',
    description: 'Xôi ruốc, chả, trứng ốp la',
    normalPrice: 25000,
    category: 'sticky-rice',
    imageLink: require('../img/combo-pate-xuc-xich.jpg'),
    defaultModifierSkus: ['V0353', 'V0358', 'V0364', 'V0384', 'V0350', 'V1046', 'V0543']
  },
  {
    sku: 'V0380',
    description: 'Xôi trứng chả pate',
    normalPrice: 30000,
    category: 'sticky-rice'
  },
  {
    sku: 'V0385',
    description: 'Xôi tự chọn',
    normalPrice: 18000,
    category: 'sticky-rice',
    defaultModifierSkus: ['	V0353', 'V0364', 'V0384', 'V1046']
  },
  {
    sku: 'V1410',
    description: 'Khô heo vị bò 50g',
    normalPrice: 15000,
    category: 'other',
    defaultModifierSkus: []
  },
  {
    sku: 'V1409',
    description: 'Khô gà lá chanh 50g',
    normalPrice: 15000,
    category: 'other',
    defaultModifierSkus: []
  },
  {
    sku: 'V0400',
    description: 'Cà phê đen đá 250ml',
    normalPrice: 18000,
    category: 'milk-tea',
    subCat: 'savor-drink',
    defaultModifierSkus: []
  },
  {
    sku: 'V0401',
    description: 'Cà phê sữa đá 250ml',
    normalPrice: 22000,
    category: 'milk-tea',
    subCat: 'savor-drink',
    defaultModifierSkus: []
  },
  {
    sku: 'V1091',
    description: 'Bạc xỉu đá',
    normalPrice: 22000,
    category: 'milk-tea',
    subCat: 'savor-drink',
    defaultModifierSkus: []
  },
  {
    normalPrice: 18000,
    sku: 'V0704',
    description: 'Nước ép chanh leo 500ml',
    variants: [
      {
        group: {
          size: 'small'
        },
        sku: 'V0704',
        description: 'Size M',
        normalPrice: 18000
      }
    ],
    category: 'juice'
  },
  {
    sku: 'V9081',
    description: 'Nước chanh leo (M)',
    normalPrice: 15000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9082',
    description: 'Nước chanh leo (L)',
    normalPrice: 20000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9083',
    description: 'Trà xanh kim quất (M) mới',
    normalPrice: 15000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9084',
    description: 'Trà xanh kim quất (L)',
    normalPrice: 20000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9085',
    description: 'Trà xanh chanh leo (M)',
    normalPrice: 17000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9086',
    description: 'Trà xanh chanh leo (L)',
    normalPrice: 25000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9087',
    description: 'Trà xoài thạch đào (M)',
    normalPrice: 27000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9088',
    description: 'Trà xoài thạch đào (L)',
    normalPrice: 35000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9089',
    description: 'Trà xoài chanh leo thạch đào (M)',
    normalPrice: 27000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    sku: 'V9090',
    description: 'Trà xoài chanh leo thạch đào (L)',
    normalPrice: 35000,
    category: 'milk-tea',
    subCat: 'fruit-tea'
  },
  {
    normalPrice: 25000,
    sku: 'V0710',
    category: 'milk-tea',
    subCat: 'olong-tra',
    description: 'Trà sữa bạc hà',
    variants: [
      {
        group: {
          size: 'small'
        },
        sku: 'V0710',
        description: 'Size M',
        normalPrice: 25000
      }
    ]
  },
  {
    sku: 'V2329',
    description: 'Trà Ô Long nhài sữa trân châu (M)',
    normalPrice: 27000,
    category: 'milk-tea',
    subCat: 'olong-tra'
  },
  {
    sku: 'V2330',
    description: 'Trà Ô Long nhài sữa trân châu (L)',
    normalPrice: 35000,
    category: 'milk-tea',
    subCat: 'olong-tra'
  },
  {
    sku: 'V2327',
    description: 'Trà Ô Long nhài sữa',
    normalPrice: 22000,
    category: 'milk-tea',
    subCat: 'olong-tra',
    variants: [
      {
        group: {
          size: 'small'
        },
        description: 'Size M',
        sku: 'V2327',
        normalPrice: 22000
      },
      {
        group: {
          size: 'big'
        },
        description: 'Size L',
        sku: 'V2328',
        normalPrice: 30000
      }
    ]
  },
  {
    sku: 'V2333',
    description: 'Trà Ô Long kim quất (M)',
    normalPrice: 18000,
    category: 'milk-tea',
    subCat: 'olong-tra'
  },
  {
    sku: 'V2334',
    description: 'Trà Ô Long kim quất (L)',
    normalPrice: 22000,
    category: 'milk-tea',
    subCat: 'olong-tra'
  },
  {
    sku: 'V2331',
    description: 'Trà Ô Long chanh leo (M)',
    normalPrice: 22000,
    category: 'milk-tea',
    subCat: 'olong-tra'
  },
  {
    sku: 'V2332',
    description: 'Trà Ô Long chanh leo (L)',
    normalPrice: 25000,
    category: 'milk-tea',
    subCat: 'olong-tra'
  },
  {
    sku: 'V1050',
    description: 'Đường nâu trân châu sữa size M',
    normalPrice: 30000,
    category: 'milk-tea',
    subCat: 'tiger-sugar',
    variants: [
      {
        group: {
          size: 'small'
        },
        sku: 'V1050',
        description: 'Size M',
        normalPrice: 30000
      }
    ]
  },
  {
    sku: 'V1051',
    description: 'Đường nâu trân châu sữa size L',
    normalPrice: 35000,
    category: 'milk-tea',
    subCat: 'tiger-sugar',
    variants: [
      {
        group: {
          size: 'big'
        },
        sku: 'V1051',
        description: 'Size L',
        normalPrice: 35000
      }
    ]
  },
  {
    normalPrice: 25000,
    category: 'milk-tea',
    subCat: 'hong-tra',
    sku: 'V1804',
    description: 'Trà sữa matcha (M)'
  },
  {
    sku: 'V0608',
    description: 'Trà Sữa TCDD (M)',
    normalPrice: 27000,
    category: 'milk-tea',
    subCat: 'hong-tra',
    defaultModifierSkus: ['V0621', 'V0618']
  },
  {
    sku: 'V0613',
    description: 'Trà Sữa TCDD (L)',
    normalPrice: 35000,
    category: 'milk-tea',
    subCat: 'hong-tra',
    defaultModifierSkus: ['V0621', 'V0618']
  },
  {
    normalPrice: 15000,
    sku: 'V0694',
    description: 'Hồng trà',
    variants: [
      {
        group: {
          size: 'small'
        },
        sku: 'V0694',
        description: 'Size M',
        normalPrice: 15000
      }
    ],
    category: 'milk-tea',
    subCat: 'hong-tra'
  },
  {
    normalPrice: 22000,
    sku: 'V0695',
    description: 'Hồng trà sữa',
    variants: [
      {
        group: {
          size: 'small'
        },
        sku: 'V0695',
        description: 'Size M',
        normalPrice: 22000
      },
      {
        group: {
          size: 'big'
        },
        sku: 'V0696',
        description: 'Size L',
        normalPrice: 30000
      }
    ],
    category: 'milk-tea',
    subCat: 'hong-tra'
  },
  {
    normalPrice: 25000,
    sku: 'V0708',
    description: 'Trà sữa hokkaido',
    variants: [
      {
        group: {
          size: 'small'
        },
        sku: 'V0708',
        description: 'Size M',
        normalPrice: 25000
      }
    ],
    category: 'milk-tea',
    subCat: 'hong-tra'
  },
  {
    normalPrice: 25000,
    sku: 'V0713',
    category: 'milk-tea',
    subCat: 'hong-tra',
    description: 'Hồng trà latte',
    variants: [
      {
        group: {
          size: 'small'
        },
        sku: 'V0713',
        description: 'Size M',
        normalPrice: 25000
      },
      {
        group: {
          size: 'big'
        },
        sku: 'V0714',
        description: 'Size L',
        normalPrice: 32000
      }
    ]
  },
  {
    normalPrice: 25000,
    category: 'milk-tea',
    subCat: 'hong-tra',
    sku: 'V1805',
    description: 'Trà sữa socola (M)'
  },
  {
    normalPrice: 25000,
    category: 'milk-tea',
    subCat: 'hong-tra',
    sku: 'V1807',
    description: 'Trà sữa dưa lưới (M)'
  },
  {
    normalPrice: 25000,
    category: 'milk-tea',
    subCat: 'hong-tra',
    sku: 'V1809',
    description: 'Trà sữa sầu riêng (M)'
  },
  // valentine
  // {
  //   sku: 'V2204',
  //   description: 'Hộp truffle 9 viên',
  //   normalPrice: 149000,
  //   category: 'valentine'
  // },
  // {
  //   sku: 'V2205',
  //   description: 'Hộp truffle 12 viên',
  //   normalPrice: 199000,
  //   category: 'valentine'
  // },
  // bánh trung thu
  // {
  //   sku: 'V2760',
  //   description: 'Hộp dạ cầm (4 bánh)',
  //   normalPrice: 0,
  //   category: 'mooncake',
  //   subCat: 'btt-box'
  // },
  // {
  //   sku: 'V2757',
  //   description: 'Hộp hoa mẫu đơn (2 bánh)',
  //   normalPrice: 0,
  //   category: 'mooncake',
  //   subCat: 'btt-box'
  // },
  // {
  //   sku: 'V2759',
  //   description: 'Hộp Hoa mẫu đơn (4 bánh)',
  //   normalPrice: 0,
  //   category: 'mooncake',
  //   subCat: 'btt-box'
  // },
  // {
  //   sku: 'V2758',
  //   description: 'Hộp tranh Nhật (2 bánh)',
  //   normalPrice: 0,
  //   category: 'mooncake',
  //   subCat: 'btt-box'
  // },
  // {
  //   sku: 'V2761',
  //   description: 'Hộp tranh Nhật (6 bánh)',
  //   normalPrice: 0,
  //   category: 'mooncake',
  //   subCat: 'btt-box'
  // },
  // bánh kẹo tết
  // {
  //   sku: 'V8390',
  //   description: 'Set quà tết lộc xuân',
  //   normalPrice: 500000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8391',
  //   description: 'Set quà tết như ý',
  //   normalPrice: 800000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8394',
  //   description: 'Combo mix 5 loại kẹo + bánh thuyền túi 1kg',
  //   normalPrice: 390000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8395',
  //   description: 'Combo mix 5 loại kẹo túi 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8370',
  //   description: 'Kẹo dẻo cherry túi 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8371',
  //   description: 'Kẹo dẻo chanh leo túi 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8372',
  //   description: 'Kẹo dẻo mix vị túi 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8373',
  //   description: 'Bánh thuyền gạo lứt 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8376',
  //   description: 'Kẹo nougat vị lục trà nhài sữa chua túi 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8377',
  //   description: 'Kẹo nougat vị socola hạnh nhân túi 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8378',
  //   description: 'Kẹo nougat vị sữa siêu hạt túi 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // {
  //   sku: 'V8379',
  //   description: 'Kẹo nougat mix vị túi 500g',
  //   normalPrice: 200000,
  //   category: 'banh-keo-tet'
  // },
  // bánh sinh nhật
  {
    sku: 'V2609',
    description: 'Bánh kem chữ nhật order',
    normalPrice: 560000,
    category: 'birthday-cake',
    subCat: 'bcake-order'
  },
  {
    sku: 'V1538',
    description: 'Bánh sinh nhật order size vừa - 21x8cm',
    normalPrice: 490000,
    category: 'birthday-cake',
    subCat: 'bcake-order'
  },
  {
    sku: 'V1537',
    description: 'Bánh sinh nhật order size nhỏ - 17x8cm',
    normalPrice: 350000,
    category: 'birthday-cake',
    subCat: 'bcake-order'
  },
  {
    sku: 'V1536',
    description: 'Bánh sinh nhật order size mini - 13x6cm',
    normalPrice: 230000,
    category: 'birthday-cake',
    subCat: 'bcake-order'
  },
  {
    sku: 'V1496',
    description: 'Bánh vẽ sinh nhật size vừa - 21x8cm',
    normalPrice: 400000,
    category: 'birthday-cake',
    subCat: 'bcake-order'
  },
  {
    sku: 'V1445',
    description: 'Bánh vẽ sinh nhật size nhỏ - 17x8cm',
    normalPrice: 275000,
    category: 'birthday-cake',
    subCat: 'bcake-order'
  },
  {
    sku: 'V1444',
    description: 'Bánh vẽ sinh nhật size mini - 13x6cm',
    normalPrice: 190000,
    category: 'birthday-cake',
    subCat: 'bcake-order'
  },
  // Phụ kiện
  {
    sku: 'V2616',
    description: 'Mũ sinh nhật giấy hình vương miện (chiếc)',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 5000
  },
  {
    sku: 'V1426',
    description: 'Mũ sinh nhật',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 5000
  },
  {
    sku: 'V1790',
    description: 'In ảnh bánh sinh nhật',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 20000
  },
  {
    sku: 'V1457',
    description: 'Que cắm Love you Mom',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 15000
  },
  {
    sku: 'V1278',
    description: 'Que cắm Happy birthday tròn vàng 16cm',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 9000
  },
  {
    sku: 'V1279',
    description: 'Nến sinh nhật nhũ vàng(10 chiếc)',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 10000
  },
  {
    sku: 'V1360',
    description: 'Bộ dao dĩa đĩa nhựa bánh sinh nhật Savor',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 10000
  },
  {
    sku: 'V1403',
    description: 'Nến chữ Happy Birthday (màu ngẫu nhiên)',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 25000
  },
  {
    sku: 'V1413',
    description: 'Nến nhũ số 0',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1414',
    description: 'Nến nhũ số 1',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1415',
    description: 'Nến nhũ số 2',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1416',
    description: 'Nến nhũ số 3',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1417',
    description: 'Nến nhũ số 4',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1418',
    description: 'Nến nhũ số 5',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1419',
    description: 'Nến nhũ số 6',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1420',
    description: 'Nến nhũ số 7',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1421',
    description: 'Nến nhũ số 8',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1422',
    description: 'Nến nhũ số 9',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 8000
  },
  {
    sku: 'V1423',
    description: 'Nến pháo bông sinh nhật(1 chiếc)',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 30000
  },
  {
    sku: 'V1953',
    description: 'Hộp gato mica trong suốt 22cm kèm ruy băng',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 40000
  },
  {
    sku: 'V1954',
    description: 'Hộp gato mica trong suốt 26cm kèm ruy băng',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 45000
  },
  {
    sku: 'V1955',
    description: 'Hộp gato mica trong suốt 30cm kèm ruy băng',
    subCat: 'accessory',
    category: 'birthday-cake',
    normalPrice: 50000
  },
  {
    category: 'birthday-cake',
    subCat: 'accessory',
    sku: 'V2203',
    description: 'Thiệp valentine (bộ)',
    normalPrice: 8000
  },
  {
    category: 'birthday-cake',
    subCat: 'accessory',
    sku: 'V2208',
    description: 'Que cắm Happy Valentine My Love (chiếc)',
    normalPrice: 0
  },
  // {
  //   category: 'gift',
  //   sku: 'V2857',
  //   description: 'Gói tương ớt Chinsu',
  //   normalPrice: 3000
  // },
  // {
  //   category: 'gift',
  //   sku: 'V2340',
  //   description: 'Sữa Dutch Lady',
  //   normalPrice: 10000
  // },
  // {
  //   category: 'gift',
  //   sku: 'V2469',
  //   description: 'Sprite tặng (Baemin) (chai)',
  //   normalPrice: 0
  // },
  // {
  //   category: 'gift',
  //   sku: 'V2470',
  //   description: 'Fanta tặng (Baemin) (chai)',
  //   normalPrice: 0
  // },
  // {
  //   category: 'gift',
  //   sku: 'V2570',
  //   description: 'Pepsi tặng 245ml (ShopeeFood) (lon)',
  //   normalPrice: 10000
  // },
  // {
  //   category: 'gift',
  //   sku: 'V2638',
  //   description: 'Coca lon (tặng kèm đơn Grab)',
  //   normalPrice: 10000
  // },
  {
    description: 'Set quà Tết Tân Xuân',
    sku: 'V9329',
    normalPrice: 300000,
    category: 'banh-keo-tet',
    collections: ['hoi-cho']
  },
  {
    description: 'Set quà Hỷ Lân',
    sku: 'V9330',
    normalPrice: 500000,
    category: 'banh-keo-tet',
    collections: ['hoi-cho']
  },
  {
    description: 'Set quà Lụa Là',
    sku: 'V9331',
    normalPrice: 800000,
    category: 'banh-keo-tet',
    collections: ['hoi-cho']
  },
  {
    description: 'Set quà Hạc Quý',
    sku: 'V9332',
    normalPrice: 1200000,
    category: 'banh-keo-tet',
    collections: ['hoi-cho']
  },
  {
    description: 'Lọ kẹo nougat sữa hạt 150g',
    sku: 'V9323',
    normalPrice: 72000,
    category: 'banh-keo-tet',
    collections: ['hoi-cho']
  },
  {
    description: 'Lọ cookies rong biển trứng muối 150g',
    sku: 'V9278',
    normalPrice: 72000,
    category: 'banh-keo-tet',
    collections: ['hoi-cho']
  },
  {
    description: 'Lọ bánh quy cookies hạnh nhân 150g',
    sku: 'V9324',
    normalPrice: 69000,
    category: 'banh-keo-tet',
    collections: ['hoi-cho']
  },
  {
    description: 'Lọ xoài sấy dẻo 150g',
    sku: 'V9326',
    normalPrice: 85000,
    category: 'banh-keo-tet',
    collections: ['hoi-cho']
  },
  {
    description: 'Lọ khô heo vị bò 130g',
    sku: 'V9327',
    normalPrice: 59000,
    category: 'banh-keo-tet',
    collections: ['hoi-cho']
  },
  {
    description: 'Lọ táo đỏ 150g',
    sku: 'V9325',
    normalPrice: 59000,
    category: 'banh-keo-tet',
    collections: ['hoi-cho']
  },
  {
    description: 'Kẹo nougat túi 500g',
    sku: 'V9335',
    normalPrice: 190000,
    category: 'banh-keo-tet',
    collections: ['hoi-cho']
  },
  {
    description: 'Bánh su kem hoa quả',
    sku: 'V8822',
    normalPrice: 120000,
    category: 'birthday-cake',
    collections: ['hoi-cho']
  },
  {
    description: 'Bánh su kem hoa quả (bánh lẻ)',
    sku: 'V9405',
    normalPrice: 10000,
    category: 'birthday-cake',
    collections: ['hoi-cho']
  },
  {
    description: 'Cupcake chà bông trứng muối',
    sku: 'V9283',
    normalPrice: 390000,
    category: 'birthday-cake',
    collections: ['hoi-cho']
  },
  {
    description: 'Cupcake chà bông trứng muối (bánh lẻ)',
    sku: 'V9406',
    normalPrice: 20000,
    category: 'birthday-cake',
    collections: ['hoi-cho']
  },
  {
    description: 'Panna cotta mix vị',
    sku: 'V9354',
    normalPrice: 100000,
    category: 'birthday-cake',
    collections: ['hoi-cho']
  },
  {
    description: 'Panna cotta mix vị (cốc lẻ)',
    sku: 'V9405',
    normalPrice: 10000,
    category: 'birthday-cake',
    collections: ['hoi-cho']
  },
  {
    description: 'Set Classic Tiramisu - Set 25 cốc',
    sku: 'V9069',
    normalPrice: 490000,
    category: 'birthday-cake',
    collections: ['hoi-cho']
  },
  {
    description: 'Set Classic Tiramisu set 25 (cốc lẻ)',
    sku: 'V9406',
    normalPrice: 20000,
    category: 'birthday-cake',
    collections: ['hoi-cho']
  },
  {
    description: 'Set mousse trang trí hoa quả tươi - set 25 cốc',
    sku: 'V8327',
    normalPrice: 490000,
    category: 'birthday-cake',
    collections: ['hoi-cho']
  },
  {
    description: 'Set mousse trang trí hoa quả tươi set 25 (cốc lẻ)',
    sku: 'V9406',
    normalPrice: 20000,
    category: 'birthday-cake',
    collections: ['hoi-cho']
  },
  {
    description: 'Set nguyên liệu làm kẹo nougat 3 vị tiramisu, sữa, Oreo',
    sku: 'B11674',
    normalPrice: 129000,
    category: 'abby-set-nguyen-lieu',
    collections: ['hoi-cho']
  },
  {
    description: 'Set nguyên liệu làm kẹo nougat 3 vị hoa quả dâu, xoài, việt quất',
    sku: 'B11675',
    normalPrice: 129000,
    category: 'abby-set-nguyen-lieu',
    collections: ['hoi-cho']
  },
  {
    description: 'Set nguyên liệu làm bánh bông tuyết hoàng kim',
    sku: 'B11925',
    normalPrice: 129000,
    category: 'abby-set-nguyen-lieu',
    collections: ['hoi-cho']
  },
  {
    description: 'Set nguyên liệu làm bánh bông tuyết môn tím',
    sku: 'B11926',
    normalPrice: 119000,
    category: 'abby-set-nguyen-lieu',
    collections: ['hoi-cho']
  },
  {
    description: 'Set nguyên liệu làm táo đỏ kẹp sữa hạt điều vị nguyên bản',
    sku: 'B11824',
    normalPrice: 89000,
    category: 'abby-set-nguyen-lieu',
    collections: ['hoi-cho']
  },
  {
    description: 'Set nguyên liệu làm bánh quy hình bông hoa socola',
    sku: 'B12069',
    normalPrice: 45000,
    category: 'abby-set-nguyen-lieu',
    collections: ['hoi-cho']
  },
  {
    description: 'Set nguyên liệu làm bánh quy hình bông hoa matcha',
    sku: 'B12068',
    normalPrice: 49000,
    category: 'abby-set-nguyen-lieu',
    collections: ['hoi-cho']
  },
  {
    description: 'Set nguyên liệu làm bánh quy hình hoa anh đào',
    sku: 'B12067',
    normalPrice: 52000,
    category: 'abby-set-nguyen-lieu',
    collections: ['hoi-cho']
  },
  {
    description: 'Set nguyên liệu bánh quy socola chocochip',
    sku: 'B8647',
    normalPrice: 55000,
    category: 'abby-set-nguyen-lieu',
    collections: ['hoi-cho']
  },
  {
    description: 'Set nguyên liệu bánh quy matcha chocochip',
    sku: 'B11493',
    normalPrice: 55000,
    category: 'abby-set-nguyen-lieu',
    collections: ['hoi-cho']
  },
  {
    description: 'Set nguyên liệu bánh quy red velvet chocochip',
    sku: 'B11494',
    normalPrice: 55000,
    category: 'abby-set-nguyen-lieu',
    collections: ['hoi-cho']
  },
  {
    description: 'Set nguyên liệu làm bánh brownie chocolate (kèm bơ)',
    sku: 'B11631',
    normalPrice: 129000,
    category: 'abby-set-nguyen-lieu',
    collections: ['hoi-cho']
  },
  {
    description: 'Set nguyên liệu làm Matcha crinkle cookies (20 chiếc)',
    sku: 'B11219',
    normalPrice: 49000,
    category: 'abby-set-nguyen-lieu',
    collections: ['hoi-cho']
  },
  {
    description: 'Set nguyên liệu làm bánh Chocolate crinkle cookies (20 chiếc)',
    sku: 'B10338',
    normalPrice: 49000,
    category: 'abby-set-nguyen-lieu',
    collections: ['hoi-cho']
  },
  {
    description: 'Set nguyên liệu làm Red velvet crinkle cookies (20 chiếc)',
    sku: 'B11220',
    normalPrice: 49000,
    category: 'abby-set-nguyen-lieu',
    collections: ['hoi-cho']
  }
])
