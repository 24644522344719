import React, { useContext } from 'react'
import { Segment, Divider, Menu, Icon } from 'semantic-ui-react'
import styles from './CurrentOrder.module.scss'

import { ModalSelectCustomer } from '../../../components/ModalSelectCustomer'
// import { ModalSelectVoucher } from '../../../components/ModalSelectVoucher'
import { ICustomer, IVoucher } from '../../../model/customer.model'
import { RootStoreContext } from '../../../model/root-store'
import { ModalChangePartnership } from '../../../components/ModalChangePartnership'
import { CurrentOrderItems } from './CurrentOrderItems'
import { CurrentOrderActions } from './CurrentOrderActions'
import { observer } from 'mobx-react-lite'
import { CurrentOrderCustomer } from './CurrentOrderCustomer'
import { CurrentOrderVoucher } from './CurrentOrderVoucher'
import { CurrentOrderHeader } from './CurrentOrderHeader'
import { ModalChangeShipping } from '../../../components/ModalChangeShipping'
import { partnerDiscounts } from '../../../model/partner-discounts.store'
import { ModalSelectSaleCode } from '../../../components/ModalSelectSaleCode'
import { ModalSelectVoucher } from '../../../components/ModalSelectVoucher'

export const CurrentOrder = observer(function CurrentOrder({
  onExpand
}: {
  onExpand: (any: any) => any
}) {
  const rootStore = useContext(RootStoreContext)
  const orderStore = rootStore.orderStore

  async function onSelectCustomer(customer: ICustomer) {
    orderStore.setCustomer(customer)
  }
  async function onSelectVoucher(voucher: IVoucher) {
    // console.log(voucher)
    orderStore.setVoucher(voucher)
    // console.log(orderStore.voucher, orderStore.availableVouchers)
  }
  async function validateSelectVoucherCode(code: string) {
    if ((orderStore.voucherCode || orderStore.voucherCode !== 'NONE') && !orderStore.voucher) {
      orderStore.setVoucherCode('NONE')
    } else {
      orderStore.setVoucherCode(code)
    }
    // console.log(orderStore.discounts)
    // console.log(orderStore.voucher)
    // console.log(orderStore.voucherCode)
  }
  if (orderStore.onlineOrderId) {
    orderStore.setCustomer(orderStore.onlineOrder.customer)
  }

  const uiStore = rootStore.uiStore

  return (
    <div className={styles['container']}>
      <Menu stackable attached="top" className={styles['menu']}>
        {onExpand && (
          <Menu.Item icon onClick={onExpand}>
            <Icon name={uiStore.createOrderViewLayout === 'order' ? 'arrow right' : 'arrow left'} />
          </Menu.Item>
        )}
        {!orderStore.onlineOrderId && (
          <ModalSelectCustomer onSelectCustomer={onSelectCustomer}>
            <Menu.Item>Thẻ KH</Menu.Item>
          </ModalSelectCustomer>
        )}

        <ModalChangePartnership order={orderStore}>
          <Menu.Item>Đối tác</Menu.Item>
        </ModalChangePartnership>
        <ModalChangeShipping order={orderStore}>
          <Menu.Item>Ship</Menu.Item>
        </ModalChangeShipping>
        <ModalSelectSaleCode order={orderStore}>
          <Menu.Item>Sale Code</Menu.Item>
        </ModalSelectSaleCode>
        <ModalSelectVoucher onSelectVoucher={onSelectVoucher}>
          <Menu.Item>Voucher</Menu.Item>
        </ModalSelectVoucher>
      </Menu>
      <Segment style={{ paddingBottom: 0 }} attached className={styles['order']}>
        <CurrentOrderCustomer />
        <CurrentOrderHeader />
        {orderStore.orderPlatform !== 'SAVOR_SHOP' ? null : (
          <div className={styles['combo-savor-header']} style={{ color: 'green' }}>
            <strong>Deal hấp dẫn khi mua combo Savor trực tiếp tại cửa hàng:</strong>
            <ol>
              <li>
                Bánh mì Gà nướng + Trà xanh chanh leo (M) - giá gốc 50k,{' '}
                <strong>sale còn 35k</strong>
              </li>
              <li>
                Bánh mì TNMO + Nước chanh leo (M) - giá gốc 55k, <strong>sale còn 40k</strong>
              </li>
              <li>
                Xôi gà nấm + Trà xanh chanh leo (M) - giá gốc 60k, <strong>sale còn 45k</strong>
              </li>
            </ol>
          </div>
        )}
        <CurrentOrderVoucher />
        <Divider />

        {orderStore.orderPlatform === 'GRAB_FOOD' && (
          <div>
            <label>Giảm giá đơn hàng GRAB</label>
            <select
              style={{ marginLeft: '1em' }}
              value={orderStore.voucherCode}
              onChange={event => {
                orderStore.setVoucherCode(event.target.value)
                validateSelectVoucherCode(orderStore.voucherCode || 'NONE')
              }}
            >
              <option value="NONE">Không</option>
              {partnerDiscounts
                .filter(discount => discount.orderPlatform === 'GRAB_FOOD')
                .map(discount => (
                  <option key={discount.code} value={discount.code}>
                    {discount.name || discount.code}
                  </option>
                ))}
            </select>
          </div>
        )}
        {orderStore.orderPlatform === 'NOW' && (
          <div>
            <label>Giảm giá đơn hàng NOW</label>
            <select
              style={{ marginLeft: '1em' }}
              value={orderStore.voucherCode}
              onChange={event => {
                orderStore.setVoucherCode(event.target.value)
                validateSelectVoucherCode(orderStore.voucherCode || 'NONE')
              }}
            >
              <option value="NONE">Không</option>
              {partnerDiscounts
                .filter(discount => discount.orderPlatform === 'NOW')
                .map(discount => (
                  <option key={discount.code} value={discount.code}>
                    {discount.name || discount.code}
                  </option>
                ))}
            </select>
          </div>
        )}
        {orderStore.orderPlatform === 'GO_FOOD' && (
          <div>
            <label>Giảm giá đơn hàng GO_FOOD</label>
            <select
              style={{ marginLeft: '1em' }}
              value={orderStore.voucherCode}
              onChange={event => {
                orderStore.setVoucherCode(event.target.value)
                validateSelectVoucherCode(orderStore.voucherCode || 'NONE')
              }}
            >
              <option value="NONE">Không</option>
              {partnerDiscounts
                .filter(discount => discount.orderPlatform === 'GO_FOOD')
                .map(discount => (
                  <option key={discount.code} value={discount.code}>
                    {discount.name || discount.code}
                  </option>
                ))}
            </select>
          </div>
        )}
        {orderStore.orderPlatform === 'BAEMIN' && (
          <div>
            <label>Giảm giá đơn hàng BAEMIN</label>
            <select
              style={{ marginLeft: '1em' }}
              value={orderStore.voucherCode}
              onChange={event => {
                orderStore.setVoucherCode(event.target.value)
                validateSelectVoucherCode(orderStore.voucherCode || 'NONE')
              }}
            >
              <option value="NONE">Không</option>
              {partnerDiscounts
                .filter(discount => discount.orderPlatform === 'BAEMIN')
                .map(discount => (
                  <option key={discount.code} value={discount.code}>
                    {discount.name || discount.code}
                  </option>
                ))}
            </select>
          </div>
        )}
        {orderStore.orderPlatform === 'BE_FOOD' && (
          <div>
            <label>Giảm giá đơn hàng BEFOOD</label>
            <select
              style={{ marginLeft: '1em' }}
              value={orderStore.voucherCode}
              onChange={event => {
                orderStore.setVoucherCode(event.target.value)
                validateSelectVoucherCode(orderStore.voucherCode || 'NONE')
              }}
            >
              <option value="NONE">Không</option>
              {partnerDiscounts
                .filter(discount => discount.orderPlatform === 'BE_FOOD')
                .map(discount => (
                  <option key={discount.code} value={discount.code}>
                    {discount.name || discount.code}
                  </option>
                ))}
            </select>
          </div>
        )}
        {orderStore.orderPlatform === 'NOW' && (
          <div>
            <label>Khuyến mãi</label>
            <select
              style={{ marginLeft: '1em' }}
              value={orderStore.couponCode}
              onChange={event => {
                orderStore.setCouponCode(event.target.value)
              }}
            >
              <option value="NONE">Không</option>
              <option value="FLASH_SALE_25_PERCENT">Flash Sale 25%</option>
            </select>
          </div>
        )}
        {orderStore.orderPlatform === 'GRAB_FOOD' && (
          <div>
            <label>Khuyến mãi</label>
            <select
              style={{ marginLeft: '1em' }}
              value={orderStore.couponCode}
              onChange={event => {
                orderStore.setCouponCode(event.target.value)
              }}
            >
              <option value="NONE">Không</option>
              <option value="CUISINE_GOLDEN_WEEK">Cuisine Week - Tuần Lễ Vàng</option>
              <option value="HOT_PREVENTION">Phòng Nóng Chống Nóng</option>
            </select>
          </div>
        )}

        {orderStore.orderPlatform === 'GO_FOOD' && (
          <div>
            <label>Khuyến mãi</label>
            <select
              style={{ marginLeft: '1em' }}
              value={orderStore.couponCode}
              onChange={event => {
                orderStore.setCouponCode(event.target.value)
              }}
            >
              <option value="">Không áp dụng</option>
              <option value="AN_NGON_GIA_TI_HON_BANH_NGOT">Ăn ngon giá tí hon - BÁNH NGỌT</option>
              <option value="AN_NGON_GIA_TI_HON_XOI_GA_NAM">Ăn ngon giá tí hon - XÔI GÀ NẤM</option>
              <option value="GO_FOOD_MONOPOLY">Món độc quyền</option>
            </select>
          </div>
        )}
        {orderStore.orderPlatform === 'SAVOR_SHOP' && (
          <div>
            <label>Voucher Airpay/Shopeepay</label>
            <select
              style={{ marginLeft: '1em' }}
              value={orderStore.couponCode}
              onChange={event => {
                orderStore.setCouponCode(event.target.value)
              }}
            >
              <option value="">Không áp dụng</option>
              <option value="AIRPAY_CODE">Có sử dụng</option>
            </select>
          </div>
        )}
        {orderStore.orderPlatform === 'SAVOR_SHOP' && (
          <div>
            <label>Khuyến mãi</label>
            <select
              style={{ marginLeft: '1em' }}
              value={orderStore.couponCode}
              onChange={event => {
                orderStore.setCouponCode(event.target.value)
              }}
            >
              <option value="NONE">Không</option>
            </select>
          </div>
        )}
        <CurrentOrderItems />
      </Segment>
      <CurrentOrderActions />
    </div>
  )
})
