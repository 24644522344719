import React, { Fragment, Component } from 'react'
import { every, groupBy, map, get, find, forEach, isEqual, some } from 'lodash'
import { Card, Header, Divider, Dimmer } from 'semantic-ui-react'
import { inject, observer } from 'mobx-react'
import { IProduct } from '../../model/product.store'
import { truncate } from 'lodash'
import { SellingProductInput } from '../../components/SellingProductInput'
import { RootStore } from '../../model/root-store'
import styles from './ItemGrid.module.scss'
import { FinalPrice } from '../../components/FinalPrice'
import { getOutOfStock } from '../../libs/is-oos'
import { StrikableText } from '../../components/StrikableText'
import { products } from '../../model/product.data'
import { ModalChooseCombo } from './CurrentOrder/ModalChooseCombo'
interface ICovidCombo {
  product: IProduct
  isOOS: boolean
}

interface ItemGridProps {
  category?: string | null
  collection: string | null
  showItems: boolean
  rootStore?: RootStore
}

const subCatName: any = {
  'savor-drink': 'Nước Savor',
  'fruit-tea': 'Trà Hoa Quả',
  'sua-tuoi': 'Sữa tươi',
  'tiger-sugar': 'Tiger Sugar',
  'hong-tra': 'Hồng trà',
  'dua-nuong': 'Dừa nướng',
  'olong-tra': 'Trà Ô Long',
  'luc-tra': 'Lục trà',
  'baemin-combo': 'BAEMIN',
  'now-combo': 'NOW',
  'cake-combo': 'Combo Bánh sinh nhật',
  'bread-combo': 'Combo',
  accessory: 'Phụ kiện',
  'gojek-combo': 'GOJEK',
  'grab-combo': 'Grab',
  'befood-combo': 'Befood',
  chicken: 'Gà',
  potato: 'Khoai',
  nem: 'Nem',
  'banh-ngot': 'Bánh Ngọt',
  'bong-lan': 'Bông Lan',
  'banh-mi-sot': 'Bánh Mì Sốt',
  // btt
  'btt-tradi-puree': 'Nhân nhuyễn truyền thống',
  'btt-modern-puree': 'Nhân nhuyễn hiện đại',
  'btt-salted-egg': 'Nhân trứng muối',
  'btt-ntc': 'Nhân thập cẩm',
  'btt-box': 'Hộp Trung Thu',
  // bsn
  'sinh-nhat': 'Bánh Sinh Nhật',
  'fresh-fruit': 'Bánh Hoa Quả Tươi',
  'bcake-kid': 'Bánh Trẻ Em',
  'bcake-mousse': 'Bánh Mousse Lạnh',
  'special-taste': 'Bánh vị đặc biệt',
  'bcake-bltm': 'Bông lan trứng muối',
  'bcake-flower': 'Bánh Bông hoa',
  'bcake-event': 'Bánh Sự kiện',
  'bcake- xmas': 'Bánh Giáng sinh',
  'bcake-korea': 'Bánh Hàn Quốc',
  'bcake-socola': 'Bánh Socola',
  'bcake-strawberry': 'Bánh Dâu tây',
  'bcake-order': 'Bánh Order (vẽ, order, in hình, bento, ...)'
}

export const ItemGrid = inject('rootStore')(
  observer(
    class extends Component<ItemGridProps> {
      //console.log(this.props.rootStore!.productStatusStore.productMap)

      findProducts(sku: String) {
        return find(this.props.rootStore!.productStore.products, (product: IProduct) =>
          isEqual(product.sku, sku)
        )
      }
      handleClickItem = (product: IProduct) => {
        this.props.rootStore!.orderStore.addProductToItems(product)
      }

      isOOS = (product: IProduct) => {
        const productMap = this.props.rootStore!.productStatusStore.productMap

        const outletCode = this.props.rootStore!.orderStore.getOutletCode()
        if (product.isCombo || outletCode === 'HOICHO') {
          return false
        }
        return product.variants && product.variants.length
          ? every(product.variants, variant => getOutOfStock(productMap, variant.sku))
          : getOutOfStock(productMap, product.sku)
      }

      render() {
        const { category, collection } = this.props
        const productStore = this.props.rootStore!.productStore
        const orderStore = this.props.rootStore!.orderStore
        const outletCode = orderStore.getOutletCode()
        const products = productStore.products.filter(product => {
          if (outletCode === 'HOICHO') {
            if (
              product.collections &&
              product.collections.length > 0 &&
              product.collections.some(_collection => _collection === collection)
            ) {
              return true
            } else {
              return false
            }
          }

          if (product.category === 'other') {
            switch (category) {
              case 'bread': {
                return product.description.match(/Bánh mì/gim) && get(product, 'normalPrice', 0) > 0
              }
              case 'sandwich': {
                return (
                  product.description.match(/Sandwich/gim) && get(product, 'normalPrice', 0) > 0
                )
              }
              case 'savor-drink': {
                return (
                  product.description.match(/(Cà phê|Trà thái)/gim) &&
                  get(product, 'normalPrice', 0) > 0
                )
              }
              case 'juice': {
                return product.description.match(/Nước ép/gim) && get(product, 'normalPrice', 0) > 0
              }
              case 'milk-tea': {
                return (
                  product.description.match(
                    /(Hồng trà|Sữa tươi|Tiger Sugar|Trà sữa|Xanh sữa)/gim
                  ) && get(product, 'normalPrice', 0) > 0
                )
              }
              case 'sticky-rice': {
                return product.description.match(/^Xôi/gim) && get(product, 'normalPrice', 0) > 0
              }
              case 'savor-food': {
                return (
                  product.description.match(/(Bông lan|Khô gà)/gim) &&
                  get(product, 'normalPrice', 0) > 0
                )
              }
            }
          }
          if (category) {
            return product.category === category
          }
          return true
        })
        const hasSubCat = products.some(product => Boolean(product.subCat))

        return (
          <div>
            <SellingProductInput onSelect={product => this.handleClickItem(product)} />
            <Divider />

            <Fragment>
              {products.length === 0 && <Header>Chưa có sản phẩm nào trong mục này.</Header>}
              {this.props.showItems && !hasSubCat && (
                <Card.Group itemsPerRow={3} className={`${styles['cards-dish']}`}>
                  {products.map(product => {
                    const isOOS = this.isOOS(product)
                    return product.isCombo ? (
                      <ModalChooseCombo
                        key={product.sku}
                        product={product}
                        trigger={
                          <Card
                            key={product.sku}
                            className={`${styles['card-dish']} ${styles['visible-border']}`}
                          >
                            <Card.Content>
                              <Dimmer active={isOOS} />
                              <Card.Header as="h4" className={`${styles['no-image-header']}`}>
                                <StrikableText strike={isOOS}>
                                  {truncate(product.description, {
                                    length: 40,
                                    omission: '..'
                                  })}
                                </StrikableText>
                              </Card.Header>
                              <Card.Description>
                                <FinalPrice
                                  normalPrice={product.normalPrice}
                                  salePrice={product.salePrice}
                                />
                              </Card.Description>
                            </Card.Content>
                          </Card>
                        }
                      ></ModalChooseCombo>
                    ) : (
                      <Card
                        key={product.sku}
                        onClick={() => (isOOS ? undefined : this.handleClickItem(product))}
                        className={`${styles['card-dish']} ${styles['visible-border']}`}
                      >
                        <Card.Content>
                          <Dimmer active={isOOS} />
                          <Card.Header as="h4" className={`${styles['no-image-header']}`}>
                            <StrikableText strike={isOOS}>
                              {truncate(product.description, {
                                length: 40,
                                omission: '..'
                              })}
                            </StrikableText>
                          </Card.Header>
                          <Card.Description>
                            <FinalPrice
                              normalPrice={product.normalPrice}
                              salePrice={product.salePrice}
                            />
                          </Card.Description>
                        </Card.Content>
                      </Card>
                    )
                  })}
                </Card.Group>
              )}
              {this.props.showItems && hasSubCat && (
                <>
                  {map(
                    groupBy(products, product => {
                      if (
                        product.category === 'birthday-cake' &&
                        !product.subCat &&
                        product.subCatWorkName
                      )
                        return product.subCatWorkName
                      if (product.subCat) return product.subCat
                      if (product.description.match(/(Sữa tươi|Trà sữa)/gim)) return 'sua-tuoi'
                      if (product.description.match(/(Tiger Sugar)/gim)) return 'tiger-sugar'
                      if (product.description.match(/Hồng trà/gim)) return 'hong-tra'
                      if (product.description.match(/Dừa nướng/gim)) return 'dua-nuong'
                      if (product.description.match(/Xanh sữa/gim)) return 'luc-tra'
                      return ''
                    }),
                    (products: IProduct[], subCat: string) => {
                      return (
                        <Fragment key={subCat}>
                          <Header as="h3">{subCatName[subCat] || subCat}</Header>
                          <Card.Group itemsPerRow={3} className={`${styles['cards-dish']}`}>
                            {map(products, (product: IProduct) => {
                              const isOOS = this.isOOS(product)
                              return product.isCombo ? (
                                <ModalChooseCombo
                                  key={product.sku}
                                  product={product}
                                  trigger={
                                    <Card
                                      key={product.sku}
                                      className={`${styles['card-dish']} ${styles['visible-border']}`}
                                    >
                                      <Card.Content>
                                        <Dimmer active={isOOS} />
                                        <Card.Header
                                          as="h4"
                                          className={`${styles['no-image-header']}`}
                                        >
                                          <StrikableText strike={isOOS}>
                                            {truncate(product.description, {
                                              length: 40,
                                              omission: '..'
                                            })}
                                          </StrikableText>
                                        </Card.Header>
                                        <Card.Description>
                                          <FinalPrice
                                            normalPrice={product.normalPrice}
                                            salePrice={product.salePrice}
                                          />
                                        </Card.Description>
                                      </Card.Content>
                                    </Card>
                                  }
                                ></ModalChooseCombo>
                              ) : (
                                <Card
                                  key={product.sku}
                                  onClick={() =>
                                    isOOS ? undefined : this.handleClickItem(product)
                                  }
                                  className={`${styles['card-dish']} ${styles['visible-border']}`}
                                >
                                  <Card.Content>
                                    <Dimmer active={isOOS} />
                                    <Card.Header as="h4" className={`${styles['no-image-header']}`}>
                                      <StrikableText strike={isOOS}>
                                        {truncate(product.description, {
                                          length: 40,
                                          omission: '..'
                                        })}
                                      </StrikableText>
                                    </Card.Header>
                                    <Card.Description>
                                      <FinalPrice
                                        normalPrice={product.normalPrice}
                                        salePrice={product.salePrice}
                                      />
                                    </Card.Description>
                                  </Card.Content>
                                </Card>
                              )
                            })}
                          </Card.Group>
                        </Fragment>
                      )
                    }
                  )}
                </>
              )}
            </Fragment>
          </div>
        )
      }
    }
  )
)
